import React from "react"
import Cookies from "js-cookie"
import { get } from "lodash"

class CookiesModul extends React.Component {
  constructor(props) {
    super(props);
    this.state = { openCookie: false };
  }

  componentDidMount() {
    // Validate cookies
    const popi = Cookies.getJSON("popiAct")
    const accepted = get(popi, "accepted", false)
    if (!accepted) {
      setTimeout(() => {
        this.setState({ openCookie: true })
      }, 2000) // delay for 2 seconds.
    }
  }

  acceptCookie = event => {
    event.preventDefault()
    Cookies.set(
      "popiAct",
      { accepted: true },
      { expires: 365, secure: true, sameSite: "strict" }
    )
    this.setState({ openCookie: false })
  }

  render() {
    if (this.state.openCookie) {
      return (
        <div className="fixed w-full bottom-0 bg-gradient-to-t from-transparent-half to-transparent pt-12 z-50">
          <div className="w-full md:p-5">
            <div className="container p-3 flex bg-white shadow-xl border-t md:rounded-xl border-gray-200 mx-auto">
              <div className="flex self-center text-center bg-bluelight-200 h-12 w-12 rounded-lg flex-shrink-0 mr-4">
                <i className="text-sm self-center text-blue-500 text-lg fas fa-cookie-bite mx-auto"></i>
              </div>
              <p className="flex-1 self-center text-xs md:text-sm text-gray-600">
              Flow uses cookies to ensure you get the best experience on our website.
                <a
                  href="/privacypolicy"
                  target="_blank"
                  className="text-orange-500 underline ml-2"
                >
                  Learn more
                </a>
              </p>
              <button
                onClick={(event) => this.acceptCookie(event)}
                className="self-center inline-block bg-orange-500 text-white rounded-lg py-3 px-5 cursor-pointer flex-shrink-0 ml-4"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )
    }

    return (<></>)
  }
}

export default CookiesModul
