import React from "react"
import { useUrlSearchParams } from "use-url-search-params"

import Cookies from 'universal-cookie';

const SaveCookieParams = ({ article }) => {
  
  const cookies = new Cookies();
  const [params] = useUrlSearchParams({});

  if (params.agency_slug !== undefined) { cookies.set('agency_slug', params.agency_slug, { path: '/' }); }
  if (params.organisation_id !== undefined) { cookies.set('organisation_id', params.organisation_id, { path: '/' }); }
  if (params.utm_source !== undefined) { cookies.set('utm_source', params.utm_source, { path: '/' }); }
  if (params.utm_medium !== undefined) { cookies.set('utm_medium', params.utm_medium, { path: '/' }); }
  if (params.utm_campaign !== undefined) { cookies.set('utm_campaign', params.utm_campaign, { path: '/' }); }
  if (params.utm_content !== undefined) { cookies.set('utm_content', params.utm_content, { path: '/' }); }

  console.log(cookies.get('utm_source') + " " + cookies.get('utm_medium') + " " + cookies.get('utm_campaign') + " " + cookies.get('utm_content'));
  
  return (
    <span></span>
  )
}

export default SaveCookieParams
